















































































































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'

@Component({ name: 'Strategy' })
export default class sensitiveManage extends VueBase {
  private viewDialog = false
  private previewData = ''
  private vul_levels = [
    {id: 1, name_value: "高危"},
    {id: 2, name_value: "中危"},
    {id: 3, name_value: "低危"},
    {id: 5, name_value: "提示"},
  ]
  private vul_levels_map = {}
  private dialogForm: any = {
    vul_name: '',
    vul_type: '',
    state: 'enable',
    vul_desc: '',
    level_id: undefined,
    vul_fix: '',
  }
  private levelsColor: any = ['', '--color-risk-high', '--color-risk-med', '--color-risk-low', '--color-risk-no', '--color-risk-tip']
  private rules = {
    vul_name: [
      {
        required: true,
        message: this.$t('views.strategyManage.notnull') as string,
        trigger: 'blur',
      },
    ],
    vul_desc: [
      {
        required: true,
        message: this.$t('views.strategyManage.notnull') as string,
        trigger: 'blur',
      },
    ],
    level_id: [
      {
        required: true,
        message: this.$t('views.strategyManage.notnull') as string,
        trigger: 'blur',
      },
    ],
  }
  async preview(desc: string) {
    this.viewDialog = true
    this.previewData = desc
  }

  created() {
    if (this.$route.query.id) {
      this.getStrategy()
    }
  }
  async getStrategy() {
    const res = await this.services.setting.getStrategy(this.$route.query.id)
    if (res.status === 201) {
      this.dialogForm = res.data
    } else {
      this.$message.error(res.msg)
    }
  }
  dialogEnter() {
    const form: any = this.$refs.ruleForm
    form.validate((valid: boolean) => {
      if (valid) {
        if (this.$route.query.id) {
          this.editStrategyApi()
        } else {
          this.addStrategyApi()
        }
      }
    })
  }
  async goBack() {
    this.$router.push({ name: 'passivePolicy' })
  }
  async addStrategyApi() {
    const { status, msg } = await this.services.setting.addManage({
      vul_name: this.dialogForm.vul_name,
      vul_desc: this.dialogForm.vul_desc,
      vul_fix: this.dialogForm.vul_fix,
      vul_type: this.dialogForm.vul_name,
      level_id: this.dialogForm.level_id,
      state: this.dialogForm.state,
    })
    if (status === 201) {
      this.$message.success('新增策略成功')
      this.goBack()
    } else {
      this.$message.error('新增策略失败，请重试')
    }
  }
  async editStrategyApi() {
    this.loadingStart()
    const { status, msg } = await this.services.setting.updateManage(
      this.dialogForm.id,
      {
        vul_name: this.dialogForm.vul_name,
        vul_desc: this.dialogForm.vul_desc,
        vul_fix: this.dialogForm.vul_fix,
        vul_type: this.dialogForm.vul_name,
        level_id: this.dialogForm.level_id,
        state: this.dialogForm.state,
      }
    )
    this.loadingDone()
    if (status === 201) {
      this.$message.success('编辑策略成功')
      this.goBack()
    } else {
      this.$message.error('编辑策略失败，请重试')
    }
  }
  get userInfo(): { username: string } {
    return this.$store.getters.userInfo
  }
}
